
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

interface ProfileDetails {
  con_prenom: string;
  con_nom: string;
  con_role: string;
  con_tel1: string;
  con_tel2: string;
  con_ccoid: string;
  con_mail: string;
  con_newmail: string;
  con_password: string;
  con_password_new: string;
  con_password_new_confirm: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      con_prenom: Yup.string().nullable().required("Le prénom est obligatoire"),
      con_nom: Yup.string().nullable().required("Le nom est obligatoire"),
      con_role: Yup.string().nullable().required("La fonction est obligatoire"),
      con_tel1: Yup.string().nullable().label("Télephone Fixe"),
      con_tel2: Yup.string().nullable().label("Télephone Portable"),
      con_ccoid: Yup.string().nullable().label("CCO ID"),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string()
        .required("Mot de passe actuel obligatoire")
        .label("Current password"),
      newpassword: Yup.string()
        .min(8)
        .required("Mot de passe actuel obligatoire")
        .label("Password"),
      confirmpassword: Yup.string()
        .min(8)
        .required("Confirmation du nouveau Mot de passe obligatoire")
        .oneOf(
          [Yup.ref("newpassword"), null],
          "Les nouveaux mots des passes doivent être identiques"
        )
        .label("Password Confirmation"),
    });

    const profileDetails = ref<ProfileDetails>({
      con_prenom: "",
      con_nom: "",
      con_role: "",
      con_tel1: "",
      con_tel2: "",
      con_ccoid: "",
      con_mail: "",
      con_newmail: "",
      con_password: "",
      con_password_new: "",
      con_password_new_confirm: "",
    });

    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("updateProfil", profileDetails)
          .then(({ data }) => {
            // console.log(data["results"]);
            submitButton1.value?.removeAttribute("data-kt-indicator");

            store.commit(Mutations.SET_USER, data["results"]);

            Swal.fire({
              text: "Changement de vos informations personnelles correctement effectué !",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("updateMail", profileDetails)
          .then(({ data }) => {
            // console.log(data);
            updateEmailButton.value?.removeAttribute("data-kt-indicator");

            if (data["results"] !== 0) {
              store.commit(Mutations.SET_USER, data["results"]);
              emailFormDisplay.value = false;
              profileDetails.value.con_mail = profileDetails.value.con_newmail;
              Swal.fire({
                text: "Changement de votre Email correctement effectué !",
                icon: "success",
                confirmButtonText: "Ok",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            } else {
              Swal.fire({
                text: "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.",
                icon: "error",
                confirmButtonText: "Ok",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("password", {
            username: profileDetails.value.con_mail,
            password: profileDetails.value.con_password,
            newPassword: profileDetails.value.con_password_new,
          })
          .then(({ data }) => {
            // console.log(data);
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            passwordFormDisplay.value = false;
            Swal.fire({
              text: "Changement de votre mot de passe correctement effectué !",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .catch(() => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.",
              icon: "error",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Paramètres du compte", [""]);
      let meAndMyself = await getAxios("/me");
      // console.log(meAndMyself);

      profileDetails.value.con_prenom = meAndMyself.con_prenom;
      profileDetails.value.con_nom = meAndMyself.con_nom;
      profileDetails.value.con_role = meAndMyself.con_role;
      profileDetails.value.con_tel1 = meAndMyself.con_tel1;
      profileDetails.value.con_tel2 = meAndMyself.con_tel2;
      profileDetails.value.con_ccoid = meAndMyself.con_ccoid;
      profileDetails.value.con_mail = meAndMyself.con_mail;
      profileDetails.value.con_newmail = meAndMyself.con_mail;
      profileDetails.value.con_password = "";
      profileDetails.value.con_password_new = "";
      profileDetails.value.con_password_new_confirm = "";
    });

    return {
      submitButton1,
      saveChanges1,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
    };
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
